@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Questrial&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

body {
  font-family: "Questrial", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.bg-color {
  background-color: #3eb489;
}
.login-form {
  background-color: #f9f9f9;

  & .title {
    font-weight: bold;
    padding-bottom: 10px;
    color: #3eb489;
  }
}

.cus-btn {
  background-color: #3eb489 !important;
  color: #fff !important;
  border: 1px solid #3eb489 !important;
  box-shadow: 0 0 0 1px #3eb48926, 0 1px 1px #3eb48926, 0 3px 3px #3eb48926,
    0 11px 12px #3eb48926, 0 -6px 20px #3eb489 inset !important;
}

.disabled-row {
  pointer-events: none;
  opacity: 0.5;
  background-color: #f5f5f5;
}

.slide h1 {
  transition: all 0.3s ease;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 0;
}

.slide button {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide p {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
}

.slide section * {
  transition: all 0.3s ease;
}

.slide section img {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
  opacity: 0;
}

.slide section span {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  opacity: 0;
}

.slide section span strong {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
  opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

.slide.animateOut h1 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.slide.animateOut p {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.slide.animateOut section span {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.slide.animateOut section span strong {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-wrapper {
  position: relative;
  height: calc(100vh - 90px);
  overflow: hidden;
}

.slide,
.slider-content {
  height: 100vh;
  background-size: cover !important;
}
.slider-content::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(#3baa81)
  );
  background: linear-gradient(transparent, #3baa81);
  content: "";
}
.slide::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(#3baa81)
  );
  background: linear-gradient(transparent, #3baa81);
  bottom: 0;
  left: 0;
}

.banner-wrapper .slick-arrow {
  position: absolute;
  top: 50%;
  /* transform: translateY(-50%); */
  z-index: 10;

  width: 42px;
  height: 70px;
  cursor: pointer;
  background-size: contain;
  background: #fff;
  color: #000;
}

.banner-wrapper .slick-arrow:hover,
.banner-wrapper .slick-arrow:hover {
  background: #c6c6c6;
}

.banner-wrapper .slick-prev {
  left: 10px;
}

.banner-wrapper .slick-next {
  right: 10px;
}

.slider-content {
  text-align: center;
  position: relative;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  z-index: 9;
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 64px;
  line-height: 1;
}

.slider-content .inner p {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider-content section span {
  color: #ffffff;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #ffffff;
  font-size: 14px;
  display: block;
}

@media (max-height: 500px) {
  .slider-wrapper,
  .slide {
    height: calc(100vh - 75px);
  }
}

@media (max-width: 640px) {
  .slider-wrapper,
  .slide {
    height: calc(80vh - 75px);
  }
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

.problems-wrapper,
.aboutus-wrapper,
.contactus-wrapper {
  padding: 50px 15px;
}
.problems-content {
  background-color: rgba(111, 179, 154, 0.03);
  padding: 120px 60px;
}
.whyjoin-wrapper {
  padding: 50px 15px;
  background-color: #000;
  color: #fff;
}
.image-container {
  margin: 20px;
  overflow: hidden;
  display: inline-block;
  will-change: transform;
}

.image-container img {
  width: 100%;
  height: auto;
  display: block;
}

.about__shape-1 {
  position: absolute;
  z-index: -1;
  left: -11%;
  top: 30%;
}
.about__shape-2 {
  position: absolute;
  left: -24%;
  top: 17%;
  z-index: -1;
}
.about__shape-3 {
  position: absolute;
  right: -7%;
  top: 5%;
}
.aboutus-content,
.testimonials-content {
  margin: 70px 15px;
}
.icon {
  position: absolute;
  top: 5px;
  left: -40px;
}
.testimonials-wrapper {
  background: linear-gradient(180deg, #cef5e6 0%, #ffffff 100%);
  padding: 50px 15px;
}
.testimonials__item-inner {
  margin: 0px 24px 0px 24px;
}
.testimonials-wrapper .slick-arrow {
  background-color: rgba(255, 255, 255, 0.42);
  font-size: 14px;
  width: 52px;
  color: #000;
  border-radius: 5px;
  height: 52px;
}
.testimonials-wrapper .slick-prev {
  left: -54px;
  right: auto;
}
.testimonials-wrapper .slick-next {
  right: -54px;
  left: auto;
}
.testimonials__content {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
}
.testimonials__figure {
  margin: 0px 0px 15px 0px;
}
.testimonials__comment {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  background-color: #ffffff;
  padding: 44px 44px 44px 44px;
  margin: 0px 0px 38px 0px;
  border-radius: 24px 24px 24px 24px;
  box-shadow: 0px 13px 22px 0px rgba(25, 25, 25, 0.08);
  position: relative;
  line-height: 1.7;
}
.testimonials__comment::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -20px;
  left: 75%;
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 40px solid transparent;
  border-top: 20px solid #ffffff;
  margin-left: 0px;
}
.testimonials__tag-img {
  width: 94px;
  height: 94px;
  border-radius: 50%;
}
.testimonials__name {
  align-self: center;
  text-align: center;
  color: #3a3a3a;
  font-size: 15px;
  font-weight: bold;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 5px 0px;
}
.testimonials__position {
  align-self: center;
  text-align: center;
  color: rgba(0, 0, 0, 0.35);
  font-size: 13px;
  font-weight: 500;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
